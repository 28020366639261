.about-feature-right{
    width: 58%;
}

.about-feature-left{
    width: 42%;
}

/*Media query*/
@media (max-width: 760px){
    .about-feature-text, .about-feature-left, .about-feature-right{
        width: 100%;
    }
    .top-margin-mobile-20{
        margin-top: 20px;
    }

    .about-feature-right{
        margin-top: 40px;
    }

    .more-about-feature{
        margin-top: 50px;
    }

}