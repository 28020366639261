
.dropdown-label{
    display: block;
    color: rgba(32, 14, 50, 0.5);
    font-size: 17px;
}

.ant-select {
    border: 0.2px solid rgba(25, 0, 65, 0.26) !important;
    border-radius: 5px !important;
    background-color: #fff !important;
    height: 55px !important;
    font-size: 18px !important;
    margin-top: 10px !important;
    margin-bottom: 30px !important;
    outline: none !important;
}

.ant-select-selector:focus{
    border: #AB3882 !important;
}

@media screen and (max-width:768px){    
    .dropdown-container{
        width: 100%;
    }
}