.about-download-button{
    background-color: var(--color-accent);
    color: white
}

.about-download-button:hover {
    background: var(--color-accent) radial-gradient(circle, transparent 1%, var(--color-action) 1%) center/15000%;
}
.about-download-button:active {
    background-color: var(--color-action);
    background-size: 120%;
    transition: background 0s ease;
}



/*Media query*/
@media (max-width: 760px){
    .about-app-area{
        padding: 0;
    }
    .app-store-button{
        justify-content: center;
    }

}