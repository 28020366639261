.home-app-area{
    background: #27143b;
    padding: 80px 100px;
    border-radius: 10px;
    overflow: hidden;
}

.home-app-text-area{
    width: 60%;
}

.home-app-image{
    top: -0px;
    right: 170px;
    height: 100%;
}

.spiral-rope{
    top: 0px;
    right: -30px;

}

.home-app-text{
    padding-right: 60px;
}

/*Media query*/
@media (max-width: 760px){
    .home-app-area-container, .home-app-text{
        padding: 0;
    }
    .home-app-text-area{
        width: 100%;
    }

    .home-app-area{
        text-align: center;
        padding: 20px;
        border-radius: 0;
    }

    .app-store-button{
        justify-content: center;
    }

}