.radial, .last-radial{
    font-size: 40px;
}

.active-border-color{
    border: 1px solid var(--color-nav-item);
    border-radius: 50%;
}

.registration-step{
    padding: 0 20px 0 5px;
}

.active-border-color, .inactive-border-color{
    color: #F9F9FA;
    transition: color 0.5s ease;
}

.inactive-border-color{
    border: 1px solid #D6D8DF;
    border-radius: 50%;
    transition: border 0.5s ease;
}

.step-padding{
    padding: 0 20px;
}

.registration-text {
    margin-top: 10px;
}

.content-radial, .registration-text p:last-child{
    padding-left: 0px;
    text-transform: capitalize;
}

.p-radial::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #D6D8DF;
    transition: background 0.5s ease;
}


.p-radial.active-line::after {
    background-color: #FFB600;
    transition: background 0.5s;
}

.content-radial{
    text-align: left;
}

