
.contact-padding{
    padding-left: 200px;
    padding-right: 200px;
}

.contact-text{
    padding-right: 50px;
}
.contact-message{
    height: 250px;
}
.contact-message:focus{
    border: 1.8px solid #AE207B;
}
.contact-button{
    background-color: var(--color-accent);
    color: white;
    width: 15rem;
}

.contact-button:hover {
    background: var(--color-accent) radial-gradient(circle, transparent 1%, var(--color-action) 1%) center/15000%;
}
.contact-button:active {
    background-color: var(--color-action);
    background-size: 120%;
    transition: background 0s ease;
}

.gradient-overlay{
    top: 0;
    right: 0;
}

/*Media query*/
@media (max-width: 760px){
    .contact-text{
        padding-right: 0;
        text-align: justify;
    }
}