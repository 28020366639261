

.nav-links-wrapper{
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: auto;
}

.cashier-nav-wrapper{
    flex-direction: column;
    background: #2F1A43;
    width: 250px;
    height: 100%;
    align-items: center;
    padding: 30px 10px;
}

.cashier-link-item-wrapper{
    display: flex;
}

.cashier-link-title{
    color: white;
    transition: 0.3s ease;
}

.cashier-link-icon{
    padding: 2px 0px;
    fill: white;
    stroke: white;
    stroke-width: 1px;
    stroke-opacity: 1px;
    transition: 0.3s ease;
}


.cashier-link-item-wrapper:hover > .cashier-link-title{
    color: #F7941F;
}

.cashier-link-item-wrapper:hover > .cashier-link-icon{
    fill: #F7941F;
    stroke: #F7941F;
    stroke-width: 1.5px;
    stroke-opacity: 0.7;
}

 .cashier-active-link{
    color: #F7941F;
    fill: #F7941F;
    stroke: #F7941F;
    stroke-width: 1.5px;
    stroke-opacity: 0.7;
}



.bottom-card-btn{
    background: #F79420;
    border: 0.5px solid rgba(25, 0, 65, 0.13);
    border-radius: 5px;
    padding: 5px 0px;
    display: flex;
    justify-content: center;
}


