.dashboard-header{
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    height: 80px;
    padding: 0px 40px;
    position: sticky;
    top: 0;
    z-index: 2;
    /* border-bottom: 0.5px solid rgba(32, 14, 50, 0.25); */
}

.dashboard-layout{
    width: calc(100% - 250px);
    overflow-y: scroll;
}

.font-size-14em{
    font-size: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
    width : 200px;
    overflow: hidden;
}
.font-size-1em{
    font-size: 1em;
}

.generate-payment{
    background: #ED2B30;
    border: 0.5px solid rgba(25, 0, 65, 0.13);
    border-radius: 5px;
    font-size: 1rem;
    padding: 0px 35px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notify-card{
    background: linear-gradient(136.67deg, #AE207B 8.34%, #E20E17 45.92%, #F79420 95.26%);
    width: 70px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content :center;
}