.dash-margin{
    margin-top: -100px;
}

.dash-padding{
    padding-left: 200px;
}
.dash-left{
    width: 40%;
}

.dash-right{
    width: 60%;
}

.dash-icon{
    height: 30px;
}
.woman-user{
    width: 100%;
}

.home-info-g{
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

.inf0-c-padding{
    padding-left: 200px;
    padding-right: 200px
}

/*Media query*/
@media (max-width: 760px){
    .dash-padding, .woman-user-area{
        padding-left: 0;
        padding-right: 0;
    }
    .dash-left{
        width: 100%;
    }
    .woman-user-container, .woman-user, .home-feature-text-area{
        width: 100%;
    }
    .home-feature{
        padding-left: 20px;
        padding-right: 20px;
    }

    .home-feature-text-area{
        margin-top: 40px;
    }
}