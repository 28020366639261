.input-container{
    width: 500px;
}
.input-label{
    display: block;
    color: rgba(32, 14, 50, 0.5);
    font-size: 17px;
}
.app-input-wrapper{
    position: relative;
}
.input-icon{
    position: absolute;
    right: 20px;
    bottom: 50%;
}
.app-input{
    outline: none;
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 55px;
    padding: 0px 20px;
    font-size: 18px;
    border: 0.2px solid rgba(25, 0, 65, 0.26);
}
.app-input:focus{
    border-color: #AB3882;
}

.show{
    position: absolute;
    right: 25px;
    font-size: 17px;
    top: 26px;
    color: rgba(32, 14, 50, 0.4);
}

@media screen and (max-width:768px){    
    .input-container{
        width: 100%;
    }
}