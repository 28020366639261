/* thank you csstricks for this background hack 
https://css-tricks.com/perfect-full-page-background-image/
*/
.image-header{
   height: 80vh;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center center;
}


.head-padding{
    padding: 50px 150px 80px 200px;
    color: #fff
}

.merchant-header-btn-width{
    width: 185px;
}
