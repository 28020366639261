.gradient-header{
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(247, 148, 32, 0.17);
    width: 100%;
}
.mobile-app{
    width: 50%;
}

.header-account-button{
    background-color: var(--color-action);
    color: white;
    
}

.header-user{
    background-image: url('../../assets/images/ebeleAdekunle.png');
}

.header-account-button:hover {
    background: var(--color-accent) radial-gradient(circle, transparent 1%, var(--color-accent) 1%) center/15000%;
}
.header-account-button:active {
    background-color: #3F4BD0;
    background-size: 120%;
    transition: background 0s ease;
}
.body-header-padding{
    padding-top: 140px;
    padding-left: 200px;
    padding-right: 150px;
}

.top-content-padding{
    padding-top: 120px;
}


/*Media query*/
@media (max-width: 760px){
    .body-header-padding{
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: column;
        text-align: center;
    }
    .header-rider{
        margin-top: 20px;
    }
    .header-action{
        margin-top: 20px;
        flex-direction: column;
    }
    .header-account-button{
        margin-bottom: 20px;
    }
    .header-testimony{
        margin-top: 40px;
    }
    .user-testimony{
        justify-content: center;
    }
}