.card-dropdown{
    background-color: #3B2353;
    color: white;
    margin: 20px 0;
    text-align: left;
}

.user-icon-angle{
    font-size: 20px;
    color: #200E32;
}
.reg-container{
    width: 60%;
    margin-top: 40px;
}
.two-row-text p{
    margin: 0;
}

.document-upload{
    padding: 20px 30px;
    background: rgba(174, 32, 124, 0.06);
    border-radius: 5px;
}
.card-dropdown p{
    margin: 0;
}
.card-dropdown-item{
    padding: 15px 20px;
}
.card-dropdown svg{
    font-size: 20px;
}
.merchant-name{
    font-size: 17px;
}

.merchant-name ~p{
    color: rgba(255, 255, 255, 0.5);
}