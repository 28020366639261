.about-header-padding{
    padding-right: 200px;
    padding-left: 200px;
    padding-top: 140px;
}

.about-header-image{
    width: 100%;
}
/*Media query*/
@media (max-width: 760px){
    .about-header-padding{
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 140px;
    }
    .about-header-text{
        width: 100%;
    }
}