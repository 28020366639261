.two-slots-top{
    grid-row: 1 / 3;
}

.two-slots-bottom{
    grid-row: 2 / 4;
}

.about-image-container{
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 5px;
    -moz-column-count: 4;
    -moz-column-gap: 0;
    column-count: 4;
    grid-column-gap: 5px;
    column-gap: 5px;
    grid-row-gap: 6px;
    row-gap: 5px;

}

.about-image-container img{
    max-width: 100%;
    height: auto;
    margin-bottom: 5px;
}