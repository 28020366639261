.mobile-nav-header{
    border-bottom: 0.5px solid rgba(32, 14, 50, 0.25);
    height: 70px;
}

.m-nav{
    background: #2F1A43;
    padding: 30px 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: 80%;
}








/* Animation */
@keyframes slidein {
    from {
        width: 0;
    }

    to {
        width: 80%;
    }
}

@keyframes slideout {
    from {
        width: 80%;
        display: block;
    }

    to {
        width: 0;
        display: none;
    }
}

@keyframes fadeIn {
    from {
        width: 100%;
        background-color: rgba(255,255,255,0);
    }

    to {
        width: 100%;
        background-color: rgba(255,255,255,0.85);
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }

    75%{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        width: 100%;
        display: block;
    }

    to {
        width: 0;
        display: none;
    }
}