.card-container{
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.card-container > .card-overlay{
    position: absolute;
    right: 0px;
    overflow: hidden;
    top: 0;
    height: 100%
}