
.info-container{
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
}

.image-grid-container{
    grid-template-rows: 1fr;
    row-gap: 20px;
}

.w-grid{
    column-gap: 20px;
}

.grid12{
    margin-left: auto;
    width: 75%;
}

.grid13{
    width: 82%;
}

.k-group{
    display: grid;
    grid-template-columns: 1fr 1fr;
}