.registration-description{
    opacity: 0.52;
    margin-bottom: 20px;
    font-size: 18px;
}

.registration-title {
    margin-top: 50px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
}
.reg-form-margin-top{
    margin-top: 30px;
}
.compliance-button{
    padding: 10px 25px;
    border: 0.5px solid  rgba(25,0,65, 0.13);
    color: var(--color-text);
}
.account-detail .input-container{
    width: 100%;
}
.compliance-button-container{
    margin-top: 40px;
}

.padded-area{
    padding: 0 10%;
}

.form-content-stepper .form-container{
    width: auto;
}


/*Media query*/
@media (max-width: 760px) {
    .form-content-stepper .form-container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .form-content-stepper{
        margin-top: 40px;
    }
    .registration-description {
        margin-bottom: 30px;
        font-size: 17px;
        line-height: 1.2rem
    }
    .registration-title {
        margin-top: 0;
    }


    .padded-area {
        padding: 0;
    }


    .stepper-layout{
        padding-top: 10%;
    }
}